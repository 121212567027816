/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import './src/translation/i18n';

import React, { useEffect, useRef } from 'react';

import { omit } from '@digital-spiders/nodash';
import { getTrackingData, storeTrackingData } from '@digital-spiders/tracking-data';
import { useGSAP } from '@gsap/react';
import * as Sentry from '@sentry/gatsby';
import { GatsbyBrowser } from 'gatsby';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Lenis from 'lenis';
// @ts-expect-error
import { ReactLenis } from 'lenis/react';
import { SITE_DOMAIN } from './src/constants';
import { LocationContext } from './src/contexts/LocationContext';
import { GlobalStateProvider } from './src/state/globalStateContext';

gsap.registerPlugin(useGSAP, ScrollTrigger);

type LenisRef = {
  wrapper?: HTMLElement;
  content?: HTMLElement;
  lenis?: Lenis;
};

const BrowserContainer: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  const lenisRef = useRef<LenisRef | null>(null);

  useEffect(() => {
    function update(time) {
      lenisRef.current?.lenis?.raf(time * 1000);
    }
    gsap.ticker.add(update);
    return () => {
      gsap.ticker.remove(update);
    };
  }, []);
  return (
    /* <I18nextProvider i18n={i18n}> */
    <ReactLenis
      ref={lenisRef}
      root
      autoRaf={false}
      options={{
        easing: t => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
        duration: 0.6,
      }}
    >
      <GlobalStateProvider>
        <LocationContext.Provider value={props.location}>{element}</LocationContext.Provider>
      </GlobalStateProvider>
    </ReactLenis>
    /* </I18nextProvider> */
  );
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = props => {
  return <BrowserContainer {...props} />;
};

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  storeTrackingData({
    trackingData: {
      ...omit(
        getTrackingData({
          currentDomain: SITE_DOMAIN,
          rootDomain: SITE_DOMAIN,
        }),
        ['sourceUrl'],
      ),
    },
    rootDomain: SITE_DOMAIN,
  }).catch(error => {
    console.error('Failed to store tracking data', error);
    Sentry.captureException(error);
  });

  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
  }
};
